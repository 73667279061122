'use client';

import { EyeHide, EyeShow } from '@drift-labs/icons';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import { twMerge } from 'tailwind-merge';

interface Props {
	className?: string;
}

const ShowHideBalanceButton = ({ className = '' }: Props) => {
	const [currentSettings, setCurrentSettings] = useCurrentSettings();
	const { showAccountValues } = currentSettings;
	return (
		<div
			onClick={(e) => {
				setCurrentSettings({
					...currentSettings,
					showAccountValues: !showAccountValues,
				});
				e.stopPropagation();
			}}
			className={twMerge(
				`text-darkBlue-30 hover:text-darkBlue-20 cursor-pointer h-4 w-4 flex items-center justify-center`,
				className
			)}
		>
			{showAccountValues ? <EyeShow /> : <EyeHide />}
		</div>
	);
};

export default ShowHideBalanceButton;
