'use client';

import { useCallback } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useSafePush from 'src/hooks/useSafePush';
import UI_UTILS from 'src/utils/uiUtils';

const useGoToRoute = () => {
	const authorityParam = useDriftStore((s) => s.authorityParam);
	const safePush = useSafePush();

	const goToRoute = useCallback(
		(href: string) => {
			if (href.includes('https')) {
				UI_UTILS.isWindowDefined() && window.open(href, '_blank');
				return;
			}

			let delimiter;
			if (authorityParam?.length == 0) {
				delimiter = '';
			} else if (href.includes('?')) {
				delimiter = '&';
			} else {
				delimiter = '?';
			}

			safePush(`${href}${delimiter}${authorityParam ?? ''}`);
		},
		[safePush, authorityParam]
	);

	return goToRoute;
};

export default useGoToRoute;
