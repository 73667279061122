'use client';

import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren<{
	expanded: boolean;
	expandedHeight?: string;
	className?: string;
}>;

const startOpenedDrawer = (animationHeight: string) => keyframes`
0% {
	max-height: ${animationHeight};
	opacity: 100%;
}
100% {
    max-height: ${animationHeight};
	opacity: 100%;
}`;

const startClosedDrawer = () => keyframes`
0% {
    max-height: 0px;
	opacity: 100%;
}
100% {
    max-height: 0px;
	opacity: 0%;
}`;

const openDrawer = (animationHeight: string) => keyframes`
0% {
    max-height: 0px;
	opacity: 0%;
}
100% {
    max-height: ${animationHeight};
	opacity: 100%;
}`;

const closeDrawer = (animationHeight: string) => keyframes`
0% {
    max-height: ${animationHeight};
	opacity: 100%;
}
100% {
    max-height: 0px;
	opacity: 0%;
}`;

const AnimatedDrawer = styled.div<{
	expanded: boolean;
	animationHeight: string;
	initial?: boolean;
}>`
	overflow: hidden;
	animation: ${(props) =>
			props.expanded
				? props.initial
					? startOpenedDrawer(props.animationHeight)
					: openDrawer(props.animationHeight)
				: props.initial
				? startClosedDrawer()
				: closeDrawer(props.animationHeight)}
		120ms linear forwards;
`;

/**
 * A toggleable drawer which animates between the required height and minimized
 * @param props
 * @returns
 */
const AnimatedToggleDrawer = (props: Props) => {
	const drawerRef = useRef<HTMLDivElement | null>(null);
	const initialExpandedVal = useRef(props.expanded);
	const expandedHasFlippedRef = useRef(false);

	const [height, setHeight] = useState(props.expandedHeight ?? 'auto');

	useEffect(() => {
		if (
			drawerRef.current &&
			drawerRef.current.firstElementChild &&
			!props.expandedHeight
		) {
			requestAnimationFrame(() => {
				// delay to next frame to get the height - for minor performance gains
				setHeight(
					`${
						drawerRef.current?.firstElementChild.getBoundingClientRect().height
					}px`
				);
			});
		} else {
			setHeight(props.expandedHeight ?? 'auto');
		}
	}, [drawerRef.current, props.expandedHeight]);

	useEffect(() => {
		if (initialExpandedVal.current != props.expanded) {
			expandedHasFlippedRef.current = true;
		}
	}, [props.expanded]);

	const expandedHasFlipped =
		expandedHasFlippedRef.current ||
		initialExpandedVal.current != props.expanded;

	return (
		<AnimatedDrawer
			expanded={props.expanded}
			animationHeight={height}
			ref={drawerRef}
			style={{ height: props.expandedHeight ?? 'auto' }}
			initial={!expandedHasFlipped}
			className={twMerge('shrink-0', props.className)}
		>
			{props.children}
		</AnimatedDrawer>
	);
};

export default AnimatedToggleDrawer;
