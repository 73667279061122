'use client';

import {
	DriftClient,
	isVariant,
	PerpMarketAccount,
	SpotMarketAccount,
} from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftClientIsReady from './useDriftClientIsReady';

export const getTargetPerpMarketAccount = (
	selectedMarketIndex: number,
	driftClient: DriftClient
) => {
	return driftClient.getPerpMarketAccount(selectedMarketIndex);
};

export const getTargetSpotMarketAccount = (
	selectedMarketIndex: number,
	driftClient: DriftClient
) => {
	return driftClient.getSpotMarketAccount(selectedMarketIndex);
};

export const useTargetPerpMarketAccount = (marketId?: MarketId) => {
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const driftClientIsReady = useDriftClientIsReady();

	const [marketAccount, setMarketsAccount] =
		useState<PerpMarketAccount>(undefined);

	// Keep markets account in state in sync with blockchain
	useEffect(() => {
		if (
			!marketId ||
			!driftClientIsReady ||
			!isVariant(marketId.marketType, 'perp')
		) {
			setMarketsAccount(undefined);
			return;
		}

		setMarketsAccount(
			getTargetPerpMarketAccount(marketId.marketIndex, driftClient)
		);

		const updateHandler = () => {
			setMarketsAccount(
				getTargetPerpMarketAccount(marketId.marketIndex, driftClient)
			);
		};

		driftClient.eventEmitter.on('update', updateHandler);

		return () => {
			//todo
			try {
				driftClient.eventEmitter.removeListener('update', updateHandler);
			} catch (e) {
				// Do nothing
			}
		};
	}, [driftClient, driftClientIsReady, marketId]);

	return marketAccount;
};

export const useTargetSpotMarketAccount = (marketId: MarketId) => {
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const driftClientIsReady = useDriftClientIsReady();

	const [marketAccount, setMarketsAccount] =
		useState<SpotMarketAccount>(undefined);

	// Keep markets account in state in sync with blockchain
	useEffect(() => {
		if (
			!marketId ||
			!driftClientIsReady ||
			!isVariant(marketId.marketType, 'spot')
		) {
			setMarketsAccount(undefined);
			return;
		}

		setMarketsAccount(
			getTargetSpotMarketAccount(marketId.marketIndex, driftClient)
		);

		const updateHandler = () => {
			setMarketsAccount(
				getTargetSpotMarketAccount(marketId.marketIndex, driftClient)
			);
		};

		driftClient.eventEmitter.on('update', updateHandler);

		return () => {
			//todo
			try {
				driftClient.eventEmitter.removeListener('update', updateHandler);
			} catch (e) {
				// Do nothing
			}
		};
	}, [driftClient, driftClientIsReady, marketId]);

	return marketAccount;
};
