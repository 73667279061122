'use client';

import { useState, useEffect } from 'react';
import useDriftAccountStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import useWalletIsConnected from './useWalletIsConnected';
import useSubAccountKeys from './useSubAccountKeys';
import { singletonHook } from 'react-singleton-hook';

/**
 * This "lazily" fetches sub accounts -> it only returns a new value when the LIST of sub accounts changes (e.g. when a subaccount is created or destroyed)
 * @returns
 */
// TODO-v2 : this causes some graphical glitches because when components are using it there is a split second where the array is empty on its first render. Might need to move it up into a global provider
const useLazySubAccounts = (): AccountData[] => {
	const getAccountState = useDriftAccountStore((s) => s.get);
	const accountKeys = useSubAccountKeys();

	const [accounts, setAccounts] = useState<AccountData[]>([]);

	const isConnected = useWalletIsConnected();

	useEffect(() => {
		setAccounts(
			isConnected
				? accountKeys.map(
						(accountKey) => getAccountState().accounts[accountKey]
				  )
				: []
		);
	}, [accountKeys, isConnected]);

	return accounts;
};

export default singletonHook([], useLazySubAccounts);
