'use client';

import type { MouseEvent } from 'react';
import {
	MutableRefObject,
	PropsWithChildren,
	useEffect,
	useRef,
	useState,
} from 'react';
import { DriftTheme } from '../../environmentVariables/EnvironmentVariables';
import useDriftTheme from '../../hooks/useDriftTheme';
import UI_UTILS, { ZINDEX } from '../../utils/uiUtils';
import { twMerge } from 'tailwind-merge';

const ModalBackground = (
	props: PropsWithChildren<{
		onClose: () => void;
		contentRef: null | MutableRefObject<any>;
		id?: string;
		noBackground?: boolean;
		className?: string;
		blurClassName?: string;
		customZIndex?: string;
		isSharedBackground?: boolean;
	}>
) => {
	const backgroundRef = useRef<HTMLDivElement>(null);
	const [isClosing, setIsClosing] = useState(false);
	const isLightTheme = useDriftTheme() === DriftTheme.light;

	const closingModalFromBackground = (event: MouseEvent) => {
		// Have to check if event happened inside the modal because dropdowns (e.g. selecting asset to withdraw) are actually not children of the modal.
		const eventWasInsideModal = UI_UTILS.clickedInsideElement(
			event.nativeEvent,
			backgroundRef?.current
		);

		if (
			!eventWasInsideModal ||
			(props.contentRef.current.contains(event.target) &&
				event.target !== props.contentRef.current)
		) {
			return;
		}

		setIsClosing(true);
	};

	useEffect(() => {
		document.body.classList.add(`overflow-hidden`);

		return () => {
			document.body.classList.remove(`overflow-hidden`);
		};
	}, []);

	const handleClose = () => {
		if (isClosing) {
			props.onClose();
		}
	};

	return (
		<div
			className={twMerge(
				`fixed inset-0 w-screen h-screen overflow-auto sm:overflow-hidden transition-all`,
				props?.customZIndex ?? ZINDEX.modal,
				!props.noBackground && 'backdrop-blur',
				props.blurClassName
			)}
			aria-labelledby="modal-title"
			role="dialog"
			aria-modal="true"
			id={props.id}
			onMouseDown={closingModalFromBackground}
			ref={backgroundRef}
		>
			<div className="min-h-screen px-4 pb-20 sm:block sm:p-0">
				<div
					className={twMerge(
						`fixed inset-0 transition-opacity bg-opacity-50`,
						props?.isSharedBackground ? 'bg-opacity-50' : 'bg-opacity-0', // We use a shared background for all modals to prevent jankiness between renders, only the shared one should have any opacity
						!props.noBackground && !isLightTheme && 'bg-black',
						props.className
					)}
					aria-hidden="true"
					onMouseUp={handleClose}
				>
					{props.children}
				</div>

				<span
					className="hidden sm:inline-block sm:align-middle sm:h-screen"
					aria-hidden="true"
				>
					&#8203;
				</span>
			</div>
		</div>
	);
};

export default ModalBackground;
