'use client';

import { NOTIFICATION_CONFIGURATIONS } from 'src/constants/constants';
import { useLocalStorageStringState } from './useLocalStorageState';
import { singletonHook } from 'react-singleton-hook';

const useNotificationPlacementSetting = () =>
	useLocalStorageStringState(
		'notificationPlacement',
		NOTIFICATION_CONFIGURATIONS[0].value
	);

export default singletonHook(
	[undefined, undefined],
	useNotificationPlacementSetting
);
