'use client';

import { useMemo } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

const useSubAccountKeys = (): string[] => {
	const accounts = useDriftAccountStore((s) => s.accounts);

	const accountKeys = useMemo(() => {
		return Object.values(accounts).map((account) => account.userKey);
	}, [Object.keys(accounts).join(',')]);

	return accountKeys;
};

export default useSubAccountKeys;
