import { ArrowLeft } from '@drift-labs/icons';

import React from 'react';

const BackButton = ({
	onClick,
	...props
}: {
	onClick: React.MouseEventHandler<HTMLButtonElement>;
	stroke?: string;
	className?: string;
	style?: React.CSSProperties;
}) => {
	return (
		<button
			onClick={onClick}
			className="text-text-secondary hover:text-text-emphasis hover:cursor-pointer"
		>
			<ArrowLeft
				className={props.className}
				stroke={props.stroke ?? 'currentColor'}
				{...props}
			/>
		</button>
	);
};

export default BackButton;
