'use client';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import AnimatedToggleDrawer from 'src/components/AnimatedDrawer/AnimatedToggleDrawer';
import Chevron from 'src/components/Icons/Chevron';
import { MarginDisplayInfo } from 'src/components/MarginInfo/MarginInfo';
import ShowHideBalanceButton from 'src/components/ShowHideBalancesButton';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Text from 'src/components/Text/Text';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { SEMANTIC_STATUS } from 'src/components/Utils/SemanticChip';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useShowAccountValues from '../../../hooks/useShowAccountValues';
import useWalletIsConnected from '../../../hooks/useWalletIsConnected';
import { Open, Info } from '@drift-labs/icons';
import {
	ACCOUNT_HEALTH_PERCENT_CRITICAL,
	ACCOUNT_HEALTH_PERCENT_WARNING,
} from 'src/constants/constants';
import useAccountExists from 'src/hooks/useAccountExists';
import { twMerge } from 'tailwind-merge';
import Button from 'src/components/Button';
import useDriftActions from 'src/hooks/useDriftActions';
import useIsLiteMode from 'src/hooks/useIsLiteMode';
import useSafePush from 'src/hooks/useSafePush';

type MarginItemOption = {
	label: string;
	value: string | number;
	tooltipText?: string | React.ReactNode;
	textColorClass?: string;
};

type MarginItem = {
	label: string;
	value: string;
	options?: MarginItemOption[];
	tooltipText?: string | React.ReactNode;
	id?: string;
	showSettleButton?: boolean;
};

export const HealthIcon = (props: {
	style?: React.CSSProperties;
	className?: string;
	status?: SEMANTIC_STATUS;
}) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={props.style}
			className={props.className}
		>
			<path
				d="M11.3116 5.92194C10.3998 5.00586 9.16155 4.48935 7.86906 4.48598C6.57657 4.4826 5.33563 4.99264 4.41908 5.90394C3.50777 6.8205 2.99774 8.06143 3.00112 9.35392C3.00449 10.6464 3.521 11.8847 4.43708 12.7964L11.4976 19.8584C11.6382 19.999 11.829 20.078 12.0278 20.078C12.2267 20.078 12.4174 19.999 12.5581 19.8584L19.5826 12.8384C20.4934 11.922 21.0032 10.6813 20.9998 9.38922C20.9964 8.09709 20.4802 6.85916 19.5646 5.94744C19.1127 5.49285 18.5758 5.13176 17.9843 4.88481C17.3928 4.63786 16.7585 4.50989 16.1176 4.50822C15.4766 4.50655 14.8416 4.63121 14.2489 4.87507C13.6562 5.11893 13.1173 5.47722 12.6631 5.92944L11.9926 6.60144L11.3116 5.92194ZM18.5191 11.7794L12.0301 18.2669L5.49758 11.7359C4.86316 11.1055 4.50492 10.249 4.50154 9.35467C4.49817 8.46029 4.84994 7.60114 5.47958 6.96594C6.11444 6.33544 6.97369 5.98285 7.86844 5.98566C8.76318 5.98847 9.6202 6.34647 10.2511 6.98094L11.4661 8.19595C11.5367 8.2666 11.6208 8.32243 11.7133 8.36016C11.8058 8.39788 11.9049 8.41674 12.0049 8.41562C12.1048 8.4145 12.2034 8.39343 12.2951 8.35365C12.3868 8.31386 12.4695 8.25617 12.5386 8.18394L13.7236 6.98994C14.0385 6.67718 14.4119 6.42953 14.8226 6.26116C15.2332 6.09278 15.673 6.00698 16.1168 6.00865C16.5606 6.01032 16.9998 6.09943 17.4091 6.2709C17.8185 6.44236 18.1901 6.69282 18.5026 7.00794C19.1373 7.63863 19.4955 8.49553 19.4986 9.39028C19.5017 10.285 19.1494 11.1444 18.5191 11.7794Z"
				fill={
					props.status
						? props.status === 'green'
							? 'var(--positive-green)'
							: props.status === 'amber'
							? 'var(--brand-yellow)'
							: 'var(--negative-red)'
						: 'url(#icon-gradient)'
				}
			/>
		</svg>
	);
};

const MarginInfoPopupInfoCell = (props: {
	value: string | number;
	label: string;
	loading: boolean;
	connected: boolean;
	dropdownOptions?: {
		label: string;
		value: string | number;
		tooltipText?: string;
	}[];
	isSubItem?: boolean;
	actionIcon?: React.ReactNode;
	tooltipText?: string;
	id?: string;
}) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const display = (
		<>
			{!props.isSubItem ? (
				<Text.BODY1 className="inline-flex space-x-2 text-text-label">
					<span
						className={`flex items-center justify-between w-full ${
							props.isSubItem ? 'text-xs' : 'text-tiny'
						}`}
					>
						{props.label}
					</span>
					<button
						className={`flex items-center text-xs group-hover:text-yellow-50`}
					>
						{props.dropdownOptions ? (
							<Chevron
								direction={isExpanded ? 'down' : 'right'}
								className="w-3 h-3"
							/>
						) : (
							<span className="w-3 h-3" />
						)}
					</button>
				</Text.BODY1>
			) : (
				<Text.BODY3 className="text-text-default">
					<span className={`flex items-center justify-between w-full ml-2`}>
						{props.label}
					</span>
				</Text.BODY3>
			)}

			{!props.connected || props.loading ? (
				<SkeletonValuePlaceholder
					className="w-24 xs:h-[12px] sm:h-[14px] xs:my-[0.5px] sm:my-[2px]"
					loading={props.loading}
				/>
			) : (
				<>
					{props.isSubItem ? (
						<Text.BODY3 className="text-text-secondary">
							{props.value}
						</Text.BODY3>
					) : (
						<Text.BODY1 className="flex items-center text-text-emphasis">
							{props.value && props.actionIcon}
							<span id={`${props.id}`}>{props.value}</span>
						</Text.BODY1>
					)}
				</>
			)}
		</>
	);

	return (
		<>
			<div
				className={`w-full flex justify-between last:mb-0 px-2 ${
					!props.isSubItem &&
					`hover:bg-container-bg-hover py-2 hover:cursor-pointer select-none`
				}`}
				onClick={() => {
					if (!props.isSubItem) {
						setIsExpanded(!isExpanded);
					}
				}}
			>
				{props.tooltipText ? (
					<Tooltip
						className="flex justify-between w-full"
						content={props.tooltipText}
					>
						{display}
					</Tooltip>
				) : (
					display
				)}
			</div>
			{props?.dropdownOptions?.length > 0 && (
				<AnimatedToggleDrawer expanded={isExpanded}>
					<div>
						{props.dropdownOptions?.map((item, index) => (
							<div
								className={`${index !== 0 && 'pt-1'}`}
								key={`${item.label}-${index}`}
							>
								{item.tooltipText ? (
									<Tooltip
										content={item.tooltipText}
										className="flex justify-between flex-grow w-full"
									>
										<MarginInfoPopupInfoCell
											loading={props.loading}
											connected={props.connected}
											label={item.label}
											value={item.value}
											isSubItem={true}
										/>
									</Tooltip>
								) : (
									<MarginInfoPopupInfoCell
										loading={props.loading}
										connected={props.connected}
										label={item.label}
										value={item.value}
										isSubItem={true}
									/>
								)}
							</div>
						))}
					</div>
				</AnimatedToggleDrawer>
			)}
		</>
	);
};

const MemoizedLabel = React.memo(function MarginItemLabel(props: {
	label: string;
	tooltipText: string | React.ReactNode;
}) {
	return (
		<Text.BODY2 className="text-text-label">
			{props.tooltipText ? (
				<Tooltip
					className="w-full"
					key={props.label}
					content={props.tooltipText}
					allowHover
				>
					<Text.BODY3 className="underline decoration-dotted">
						{props.label}
					</Text.BODY3>
				</Tooltip>
			) : (
				props.label
			)}
		</Text.BODY2>
	);
});

const buildMarginItems = (
	showAccountValues: boolean,
	marginInfoDisplay: MarginDisplayInfo,
	/**
	 * If true, the maintenance requirement and initial requirement will be hidden
	 */
	isLiteMode?: boolean,
	/**
	 * If true, the health column will be returned as its own MarginItem in the return array, with the maintenance and initial reqs as options in pro mode
	 */
	separateHealthColumn?: boolean
) => {
	const maintenanceReqOption = {
		label: `Maint. Margin`,
		value: showAccountValues
			? `${marginInfoDisplay?.maintenanceReq ?? ''}${
					(marginInfoDisplay?.maintenanceReqPct &&
						` (${marginInfoDisplay?.maintenanceReqPct})`) ??
					''
			  }`
			: '$ ∗∗∗∗∗',
		tooltipText:
			'Maintenance margin is the minimum required to maintain your positions. Below that, liquidators can liquidate any portion of assets and liabilities until your account is above the minimum.',
	};

	const initialReqOption = {
		label: 'Initial Margin',
		value: showAccountValues
			? `${marginInfoDisplay?.initialReq ?? ''}${
					(marginInfoDisplay?.initialReqPct &&
						` (${marginInfoDisplay?.initialReqPct})`) ??
					''
			  }`
			: '$ ∗∗∗∗∗',
		tooltipText:
			'Initial margin is the minimum required to open a new position.',
	};

	const healthColumnItems: MarginItem[] = separateHealthColumn
		? [
				{
					label: 'Account Health',
					value: marginInfoDisplay?.health
						? `${marginInfoDisplay?.health}%`
						: '',
					id: 'health',
					tooltipText: (
						<>
							Account Health percentage is equal to 1 - Maintenance Margin /
							Maintenance Collateral.
						</>
					),
					options: isLiteMode ? [] : [maintenanceReqOption, initialReqOption],
				},
		  ]
		: [];

	return [
		{
			label: 'Net USD Value',
			value: showAccountValues ? marginInfoDisplay?.netUsdValue : '$ ∗∗∗∗∗',
			actionIcon: <ShowHideBalanceButton className="mr-1" />,
			tooltipText: 'USD Value of your deposits, borrows, and unsettled',
			id: 'net_usd_value',
			showSettleButton: true,
			options: [
				{
					label: 'Balances',
					value: showAccountValues
						? marginInfoDisplay?.accountBalance
						: '$ ∗∗∗∗∗',
				},
				{
					label: 'Unsettled',
					value: showAccountValues
						? marginInfoDisplay?.unrealisedPnl
						: '$ ∗∗∗∗∗',
					textColorClass:
						!showAccountValues || marginInfoDisplay?.unsettledPnlNum === 0
							? 'text-text-default'
							: marginInfoDisplay?.unsettledPnlNum > 0
							? 'text-positive-green'
							: 'text-negative-red',
					tooltipText: (
						<>
							This includes funding fees, position P&L, and referral fees.
							Claiming unsettled balances will update your available USDC
							balance for staking and withdrawals.
							<a
								href="https://docs.drift.trade/profit-loss/what-is-unsettled-profit-loss"
								target="_blank"
								rel="noreferrer"
								className="ml-1"
							>
								Learn More <Open size={14} className="relative top-[3px]" />
							</a>
						</>
					),
				},
			],
		},
		{
			label: 'Acct. Leverage',
			value: marginInfoDisplay?.accountLeverage,
			id: 'account_leverage',
			options: [
				{
					label: 'Total Collateral',
					value: showAccountValues
						? marginInfoDisplay?.totalCollateral
						: '$ ∗∗∗∗∗',
					tooltipText: 'Total value of collateral.',
				},
				{
					label: 'Free Collateral',
					value: showAccountValues
						? marginInfoDisplay?.freeCollateral
						: '$ ∗∗∗∗∗',
					tooltipText:
						'USD value of collateral that can be used to open new positions.',
				},
				{
					label: 'In Open Orders',
					value: showAccountValues
						? marginInfoDisplay?.totalQuoteInOrders
						: '$ ∗∗∗∗∗',
					tooltipText: `Open Orders: ${
						marginInfoDisplay?.quoteInOrders ?? 0
					} | BAL Orders: ${marginInfoDisplay?.quoteInLpOrders ?? 0}`,
				},
				separateHealthColumn ? undefined : maintenanceReqOption,
			].filter((option) => !!option),
		},
		...healthColumnItems,
	] as MarginItem[];
};

const AccountHealth = React.memo(function AccountHealthMemo(props: {
	marginInfoDisplay: MarginDisplayInfo;
}) {
	const [isSettling, setIsSettling] = useState(false);
	const actions = useDriftActions();
	const wrapperRef = useRef<HTMLDivElement>(null);
	const [expandedItemIndex, setExpandedItemIndex] = useState(-1);
	const connected = useWalletIsConnected();
	const accountExists = useAccountExists();

	const health = props.marginInfoDisplay?.health ?? 0;

	const healthClassName =
		!connected || !accountExists
			? 'text-text-disabled'
			: health >= ACCOUNT_HEALTH_PERCENT_WARNING
			? 'text-positive-green'
			: health >= ACCOUNT_HEALTH_PERCENT_CRITICAL
			? 'text-warn-yellow'
			: 'text-negative-red';

	const setStore = useDriftStore((s) => s.set);

	const showAccountValues = useShowAccountValues();

	const handleShowHealthModal = useCallback(() => {
		setStore((s) => {
			s.modals.showAccountHealthModal = true;
		});
	}, []);

	const handleExpandItem = (index: number) => {
		if (expandedItemIndex !== index) {
			setExpandedItemIndex(index);
		} else {
			setExpandedItemIndex(-1);
		}
	};

	const handleSettle = async () => {
		setIsSettling(true);
		try {
			await actions.settleAllPnls();
		} catch (err) {
			console.log(err);
		}
		setIsSettling(false);
	};

	const handleClickedOutsideContainer = useCallback(
		(event: MouseEvent) => {
			if (expandedItemIndex === -1) return;

			// If event comes from inside of wrapperRef, don't collapse
			let node = event.target as HTMLElement;
			while (node) {
				if (node === wrapperRef.current) {
					return;
				}
				node = node.parentElement;
			}

			setExpandedItemIndex(-1);
		},
		[expandedItemIndex]
	);

	useEffect(() => {
		window.document.body.addEventListener(
			'mouseup',
			handleClickedOutsideContainer
		);
		return () =>
			window.document.body.removeEventListener(
				'mouseup',
				handleClickedOutsideContainer
			);
	}, [expandedItemIndex]);

	const marginItems = buildMarginItems(
		showAccountValues,
		props.marginInfoDisplay,
		false,
		false
	);

	return (
		<div
			className={`${expandedItemIndex > -1 ? 'shadow-lg' : ''} w-full`}
			ref={wrapperRef}
		>
			<div
				className={`flex flex-row items-stretch justify-between text-text-default divide-x divide-container-border`}
				id="marginITems"
			>
				{marginItems.map((marginItem, i) => {
					return (
						<div key={marginItem.id} className="w-full px-1 my-1">
							<div
								className={`block w-full h-full text-left px-2 py-0.5 leading-5 ${
									connected
										? 'hover:bg-button-secondary-bg-hover cursor-pointer select-none'
										: ''
								}`}
								onClick={connected ? () => handleExpandItem(i) : null}
							>
								<Text.MICRO1 className="text-text-label whitespace-nowrap">
									{marginItem.label}
								</Text.MICRO1>
								<br />
								{connected ? (
									<Text.BODY3 className="flex items-center gap-1">
										<span>{marginItem.value}</span>
										<Info color="var(--text-label)" />
									</Text.BODY3>
								) : (
									<SkeletonValuePlaceholder className="w-12 h-4 mt-0.5" />
								)}
							</div>
						</div>
					);
				})}
				<div className="px-1 my-1 shrink-0">
					<div
						className={`block whitespace-nowrap h-full text-left px-2 py-0.5 leading-5 ${
							connected
								? 'hover:bg-button-secondary-bg-hover cursor-pointer select-none'
								: ''
						}`}
						onClick={connected ? handleShowHealthModal : null}
					>
						<Text.MICRO1 className="text-text-label">Health</Text.MICRO1>
						<br />
						{connected ? (
							<span className="flex items-center gap-1">
								<Text.BODY3 className={healthClassName}>{health}%</Text.BODY3>{' '}
								<Open color="var(--text-label)" />
							</span>
						) : (
							<SkeletonValuePlaceholder className="w-12 h-4 mt-0.5" />
						)}
					</div>
				</div>
			</div>
			<div
				className={`p-4 ${
					expandedItemIndex > -1 ? 'block' : 'hidden'
				} space-y-2`}
			>
				{marginItems[expandedItemIndex]?.options.map((option) => {
					return (
						<div
							className="flex flex-row items-center justify-between"
							key={option.label}
						>
							<MemoizedLabel
								tooltipText={option.tooltipText}
								label={option.label}
							/>
							<Text.BODY2
								className={twMerge('text-text-default', option.textColorClass)}
							>
								{option.value}
							</Text.BODY2>
						</div>
					);
				})}
				{marginItems[expandedItemIndex]?.showSettleButton && (
					<Button.Secondary
						size="MEDIUM"
						className="w-full mt-2"
						onClick={handleSettle}
						disabled={
							isSettling ||
							!connected ||
							props.marginInfoDisplay?.unsettledPnlNum === 0
						}
					>
						Settle
					</Button.Secondary>
				)}
			</div>
		</div>
	);
});

const MobileAndTabletAccountHealth = React.memo(
	function MobileAndTabletAccountHealth(props: {
		marginInfoDisplay: MarginDisplayInfo;
	}) {
		const isLiteMode = useIsLiteMode();
		const [isSettling, setIsSettling] = useState(false);
		const actions = useDriftActions();
		const connected = useWalletIsConnected();
		const accountExists = useAccountExists();
		const safePush = useSafePush();

		const health = props.marginInfoDisplay?.health ?? 0;

		console.log(connected, accountExists, health);

		const healthClassName =
			!connected || !accountExists
				? 'text-text-default'
				: health >= ACCOUNT_HEALTH_PERCENT_WARNING
				? 'text-positive-green'
				: health >= ACCOUNT_HEALTH_PERCENT_CRITICAL
				? 'text-warn-yellow'
				: 'text-negative-red';

		const setStore = useDriftStore((s) => s.set);

		const showAccountValues = useShowAccountValues();

		const handleShowHealthModal = useCallback(() => {
			setStore((s) => {
				s.modals.showAccountHealthModal = true;
			});
		}, []);

		const marginItems = buildMarginItems(
			showAccountValues,
			props.marginInfoDisplay,
			isLiteMode,
			true
		);

		const handleSettle = async () => {
			setIsSettling(true);
			try {
				await actions.settleAllPnls();
			} catch (err) {
				console.log(err);
			}
			setIsSettling(false);
		};

		const openUnsettledOverviewPage = () => {
			safePush('/overview/positions/#unrealised_pnl_table');
		};

		return (
			<div className="h-full">
				<div
					className={`flex flex-col sm:flex-row items-stretch h-full justify-between text-text-default sm:py-2 divide-y sm:divide-y-0 sm:divide-x divide-container-border`}
					id="marginITems"
				>
					{marginItems.map((marginItem) => {
						return (
							<div key={marginItem.id} className="w-full px-3 py-3 sm:pt-0">
								<div
									className={`w-full pt-1 pb-2 text-left flex flex-row justify-between items-center leading-5`}
								>
									<Text.BODY2 className="font-semibold text-text-label whitespace-nowrap">
										{marginItem.label}
									</Text.BODY2>
									{connected ? (
										<Text.BODY2 className="flex flex-row items-center font-semibold">
											{marginItem.value &&
												marginItem.label === 'Account Health' && (
													<HealthIcon
														style={{ width: '16px', height: '16px' }}
														className="relative top-[-1px]"
														status={
															health >= ACCOUNT_HEALTH_PERCENT_WARNING
																? 'green'
																: health >= ACCOUNT_HEALTH_PERCENT_CRITICAL
																? 'amber'
																: 'red'
														}
													/>
												)}
											<span
												className={
													marginItem.label === 'Account Health' &&
													healthClassName
												}
											>
												{marginItem.value}
											</span>
										</Text.BODY2>
									) : (
										<SkeletonValuePlaceholder className="w-12 h-4 mt-0.5" />
									)}
								</div>
								<div>
									{connected && (
										<>
											{marginItem.options?.map((option) => (
												<div
													className="flex flex-row items-center justify-between mb-2"
													key={option.label}
												>
													<MemoizedLabel
														tooltipText={option.tooltipText}
														label={option.label}
													/>
													<Text.BODY2
														className={twMerge(
															'text-text-default',
															option.textColorClass,
															option.label === 'Unsettled' && 'cursor-pointer'
														)}
														onClick={
															option.label === 'Unsettled'
																? openUnsettledOverviewPage
																: null
														}
													>
														{option.value}
														{option.label === 'Unsettled' && (
															<span className="ml-1 text-text-label">
																<Open
																	size={14}
																	className="relative top-[3px]"
																/>
															</span>
														)}
													</Text.BODY2>
												</div>
											))}
											{marginItem.showSettleButton && (
												<Button.Secondary
													size="MEDIUM"
													className="w-full mt-2"
													onClick={handleSettle}
													disabled={
														isSettling ||
														!connected ||
														props.marginInfoDisplay?.unsettledPnlNum === 0
													}
												>
													Settle
												</Button.Secondary>
											)}
											{marginItem.label === 'Account Health' && (
												<Button.Secondary
													size="MEDIUM"
													className="w-full mt-2"
													onClick={handleShowHealthModal}
													disabled={!connected}
												>
													Account Health Breakdown
												</Button.Secondary>
											)}
										</>
									)}
								</div>
							</div>
						);
					})}
					{/* <div className="px-2 shrink-0">
						<div
							className={`flex flex-row items-center justify-between sm:block whitespace-nowrap pt-3 pb-1 sm:py-1 text-left px-2 leading-5 ${
								connected
									? 'hover:bg-button-secondary-bg-hover cursor-pointer select-none'
									: ''
							}`}
							onClick={connected ? handleShowHealthModal : null}
						>
							<Text.BODY2 className="font-semibold text-text-label whitespace-nowrap">
								Health
							</Text.BODY2>
							<br />
							{connected ? (
								<div className="flex items-center gap-1">
									<Text.H3 className={healthClassName}>{health}%</Text.H3>{' '}
									<Open color="var(--text-label)" />
								</div>
							) : (
								<SkeletonValuePlaceholder className="w-12 h-4 mt-0.5" />
							)}
						</div>
					</div> */}
				</div>
			</div>
		);
	}
);

export { MobileAndTabletAccountHealth, AccountHealth };
