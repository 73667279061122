'use client';

import { useCallback, useMemo } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';

const useShowGridCustomiser = () => {
	const showGridCustomiser = useDriftStore((s) => s.showGridCustomiser);
	const set = useDriftStore((s) => s.set);

	const setShowGridCustomiser = useCallback(
		(val: boolean) => {
			set((s) => {
				s.showGridCustomiser = val;
			});
		},
		[set]
	);

	const returnVal = useMemo(() => {
		return [showGridCustomiser, setShowGridCustomiser];
	}, [showGridCustomiser, setShowGridCustomiser]);

	return returnVal as [boolean, (val: boolean) => void];
};

export default useShowGridCustomiser;
