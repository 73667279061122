'use client';

import styled from '@emotion/styled';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import React, { PropsWithChildren } from 'react';
import useResponsiveSpan, { SizeConfig } from '../useResponsiveSpan';

type GridProps = {
	className?: string;
	isMobile?: boolean;
};

const GridWrapper = styled.div<GridProps>`
	height: 100vh;
	width: 100%;
	padding-top: ${(props) => (props.isMobile ? '0px' : '5%')};
	padding-bottom: ${(props) => (props.isMobile ? '0px' : '5%')};
`;

const Grid = styled.div<GridProps>`
	height: 100%;
	width: 100%;
	display: inline-grid;
	grid-template-columns: ${Array(12).fill('1fr').join(' ')};
	align-items: ${(props) => (props.isMobile ? 'stretch' : 'start')};
	overflow-y: auto;
`;

const ModalGrid = ({ children, ...props }: PropsWithChildren<GridProps>) => {
	const isMobile = useIsMobileScreenSize();

	return (
		<GridWrapper isMobile={isMobile} {...props}>
			<Grid isMobile={isMobile} {...props}>
				{children}
			</Grid>
		</GridWrapper>
	);
};

const Cell = styled.div<{
	gridColumn: string;
}>`
	grid-column: ${(props) => props.gridColumn};
	flex-shrink: 0;
`;

const defaultColSpanConfig = {
	xs: 12,
	sm: 6,
	md: 4,
};

type GridCellProps = {
	columnSpanConfig?: SizeConfig;
	className?: string;
};

const ModalGridCell = ({
	children,
	...props
}: PropsWithChildren<GridCellProps>) => {
	const columnSpanToUse = useResponsiveSpan(
		props.columnSpanConfig ?? defaultColSpanConfig
	);

	const horizontalOutsideSpace = 12 - columnSpanToUse;
	const leftSpace = horizontalOutsideSpace / 2;

	return (
		<Cell
			gridColumn={`${leftSpace + 1} / ${leftSpace + columnSpanToUse + 1}`}
			className={props.className}
		>
			{children}
		</Cell>
	);
};

ModalGrid.Cell = ModalGridCell;
export default ModalGrid;
