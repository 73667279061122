'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { useTargetPerpMarketAccount } from './useTargetMarketAccount';

const useCurrentPerpMarketAccount = () => {
	const currentMarketId = useDriftStore(
		(s) => s.selectedMarket.current.marketId
	);
	return useTargetPerpMarketAccount(currentMarketId);
};

export default useCurrentPerpMarketAccount;
