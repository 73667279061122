'use client';

import { singletonHook } from 'react-singleton-hook';
import useTradeModeSetting, { TradeMode } from './useTradeModeSetting';
import Env from 'src/environmentVariables/EnvironmentVariables';

/**
 * Returns true if the constant is enabled and the user setting is enabled
 *
 * @returns boolean
 */
const useIsLiteMode = () => {
	const [tradeMode] = useTradeModeSetting();

	return Env.enableLiteMode && tradeMode === TradeMode.LITE;
};

export default singletonHook(false, useIsLiteMode);
