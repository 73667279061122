'use client';

import useWindowSizeString from 'src/hooks/useWindowSize';
import { useState, useEffect } from 'react';
import { SizeString } from 'src/stores/useWindowSizeStore';

export type SizeConfig = {
	[key in SizeString]?: number;
};

const sizeConfigValues: { [key in SizeString]: number } = {
	xs: 1,
	sm: 2,
	md: 3,
	lg: 4,
	xl: 5,
};

const getSizeConfigToUse = (
	windowSize: SizeString,
	sizeConfigs: SizeString[]
): SizeString => {
	let configToUse: SizeString = 'xs';

	const orderedSizeConfigs = sizeConfigs.sort(
		(a, b) => sizeConfigValues[a] - sizeConfigValues[b]
	);

	let configIndex = 0;

	while (
		configIndex <= orderedSizeConfigs.length - 1 &&
		sizeConfigValues[windowSize] >=
			sizeConfigValues[orderedSizeConfigs[configIndex]]
	) {
		configToUse = orderedSizeConfigs[configIndex];
		configIndex++;
	}

	return configToUse;
};

/**
 * Utility hook to return which span to use based on response span config and the current window size
 * @param props
 * @returns
 */
const useResponsiveSpan = (sizeConfig: SizeConfig) => {
	const windowSize = useWindowSizeString();

	const orderedSizeConfigs = Object.keys(sizeConfig).sort(
		(a, b) => sizeConfigValues[a] - sizeConfigValues[b]
	);
	const smallestConfigGiven = sizeConfig[orderedSizeConfigs[0]];

	const getCurrentSpanToUse = () => {
		const configToUse = getSizeConfigToUse(
			windowSize,
			Object.keys(sizeConfig) as SizeString[]
		);

		return sizeConfig?.[configToUse] ?? smallestConfigGiven;
	};

	const [currentSizeConfig, setCurrentSizeConfig] = useState(
		getCurrentSpanToUse()
	);

	useEffect(() => {
		setCurrentSizeConfig(getCurrentSpanToUse());
	}, [windowSize]);

	return currentSizeConfig ?? smallestConfigGiven;
};

export default useResponsiveSpan;
