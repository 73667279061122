'use client';

import useSWR from 'swr';
import useIsMainnet from './useIsMainnet';

export type S3ParamsProps = {
	status: string;
	message: string;
	alert: {
		alertType: string;
		alertData: {
			baseSymbol: string;
		};
	};
	avoidHistoryServerLoad?: string;
	statusBar: {
		status: 'warn' | 'error';
		message: string;
	};
};

// not sure why this fetcher is needed even though a global fetcher has been provided in the global SWR config
const fetcher = (url: string) =>
	fetch(url, {
		headers: {
			'Cache-Control': 'no-cache',
		},
	}).then((res) => res.json());

const useS3Params = () => {
	const isMainnet = useIsMainnet();
	const s3Url = `https://${isMainnet ? 'mainnet-beta' : 'dev'}-params.s3.${
		isMainnet ? 'eu-west-1' : 'us-east-1'
	}.amazonaws.com/params.txt`;

	const { data, error } = useSWR<S3ParamsProps>(s3Url, fetcher, {
		refreshInterval: 5 * 1000, // 60 seconds
	});

	if (error) {
		console.error(error);
	}

	return data;
};

export default useS3Params;
