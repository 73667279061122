import { HealthIcon } from 'src/components/GridSections/AccountHealth/AccountHealth';
import Text from 'src/components/Text/Text';
import { SEMANTIC_STATUS } from 'src/components/Utils/SemanticChip';
import React from 'react';
import {
	ACCOUNT_HEALTH_PERCENT_WARNING,
	ACCOUNT_HEALTH_PERCENT_CRITICAL,
} from 'src/constants/constants';
import { twMerge } from 'tailwind-merge';

const HealthDisplay = ({
	health,
	className,
}: {
	health: number;
	className?: string;
}) => {
	const status: SEMANTIC_STATUS =
		health >= ACCOUNT_HEALTH_PERCENT_WARNING
			? 'green'
			: health >= ACCOUNT_HEALTH_PERCENT_CRITICAL
			? 'amber'
			: 'red';

	return (
		<div
			className={twMerge(
				'inline-flex items-center space-x-1 min-w-[60px]',
				className
			)}
		>
			<HealthIcon status={status} className="w-4 h-4 mt-0.25" />
			<Text.BODY3
				className={`${
					status === 'green'
						? 'text-positive-green'
						: status === 'amber'
						? 'text-brand-yellow'
						: 'text-negative-red'
				}`}
			>
				{health}%
			</Text.BODY3>
		</div>
	);
};

export default HealthDisplay;
