'use client';
import useDisableScroll from 'src/hooks/useDisableScroll';
import useMounted from 'src/hooks/useMounted';
import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

type ModalPortalProps = {
	children: React.ReactNode;
	node?: any;
	customZIndex?: number;
	id?: string;
};

const ModalPortal = (props: ModalPortalProps) => {
	useDisableScroll();

	const defaultNode = useRef(null);

	const mounted = useMounted();

	if (!defaultNode.current) {
		let portalDock = document.getElementById('modal-portal-dock');

		if (!portalDock) {
			portalDock = document.createElement('div');
			portalDock.id = 'modal-portal-dock';
			document.body.appendChild(portalDock);
		}

		defaultNode.current = portalDock;
	}

	return (
		mounted &&
		ReactDOM.createPortal(
			<div style={{ zIndex: props.customZIndex ?? 100, position: 'absolute' }}>
				{props.children}
			</div>,
			props.node || defaultNode.current
		)
	);
};

export default ModalPortal;
